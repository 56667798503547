import formsSetting from './setting.js';
import formsSettingForm from './settingForm.js';
import formsSettingList from './settingList.js';
import formsAccess from './access.js';
import formsAccessForm from './accessForm.js';
import formsAccessFormRelationList from './accessFormRelationList.js';
import formsAccessFormRelationForm from './accessFormRelationForm.js';
import formsAccessList from './accessList.js';
import formsAccessOption from './accessOption.js';
import formsAccessOptionRelationForm from './accessOptionRelationForm.js';
import formsAccessOptionRelationList from './accessOptionRelationList.js';
import formsAccessOptionForm from './accessOptionForm.js';
import formsAccessOptionList from './accessOptionList.js';
import formsAccessStatus from './accessStatus.js';
import formsAccessStatusForm from './accessStatusForm.js';
import formsAccessStatusList from './accessStatusList.js';
import formsForm from './form.js';
import formsFormForm from './formForm.js';
import formsFormFormRelationList from './formFormRelationList.js';
import formsFormFormRelationForm from './formFormRelationForm.js';
import formsFormList from './formList.js';
import formsFormOption from './formOption.js';
import formsFormOptionRelationForm from './formOptionRelationForm.js';
import formsFormOptionRelationList from './formOptionRelationList.js';
import formsFormOptionForm from './formOptionForm.js';
import formsFormOptionList from './formOptionList.js';
import formsFormStatus from './formStatus.js';
import formsFormStatusForm from './formStatusForm.js';
import formsFormStatusList from './formStatusList.js';
import formsField from './field.js';
import formsFieldForm from './fieldForm.js';
import formsFieldFormRelationList from './fieldFormRelationList.js';
import formsFieldFormRelationForm from './fieldFormRelationForm.js';
import formsFieldList from './fieldList.js';
import formsFieldOption from './fieldOption.js';
import formsFieldOptionRelationForm from './fieldOptionRelationForm.js';
import formsFieldOptionRelationList from './fieldOptionRelationList.js';
import formsFieldOptionForm from './fieldOptionForm.js';
import formsFieldOptionList from './fieldOptionList.js';
import formsFieldStatus from './fieldStatus.js';
import formsFieldStatusForm from './fieldStatusForm.js';
import formsFieldStatusList from './fieldStatusList.js';
import formsContent from './content.js';
import formsContentList from './contentList.js';
import formsContentForm from './contentForm.js';
import formsFieldContentRelationList from './fieldContentRelationList.js';
import formsFieldContentRelationForm from './fieldContentRelationForm.js';
import formsContentStatus from './contentStatus.js';
import formsContentStatusForm from './contentStatusForm.js';
import formsContentStatusList from './contentStatusList.js';

const config = {
	name: 'forms',
	pagePrefixUrl: 'app',
	pageSeparateBaseUrl: 'forms',
	pageBaseUrl: 'forms',
	pageInitialUrl: 'forms',
	apiBaseUrl: process.env.URL_API_FORMS,
	title: 'Forms',
	
	formsSetting,
	formsSettingForm,
	formsSettingList,
	formsAccess,
	formsAccessForm,
	formsAccessFormRelationList,
	formsAccessFormRelationForm,
	formsAccessList,
	formsAccessOption,
	formsAccessOptionRelationForm,
	formsAccessOptionRelationList,
	formsAccessOptionForm,
	formsAccessOptionList,
	formsAccessStatus,
	formsAccessStatusForm,
	formsAccessStatusList,
	formsForm,
	formsFormForm,
	formsFormFormRelationList,
	formsFormFormRelationForm,
	formsFormList,
	formsFormOption,
	formsFormOptionRelationForm,
	formsFormOptionRelationList,
	formsFormOptionForm,
	formsFormOptionList,
	formsFormStatus,
	formsFormStatusForm,
	formsFormStatusList,
	formsField,
	formsFieldForm,
	formsFieldFormRelationList,
	formsFieldFormRelationForm,
	formsFieldList,
	formsFieldOption,
	formsFieldOptionRelationForm,
	formsFieldOptionRelationList,
	formsFieldOptionForm,
	formsFieldOptionList,
	formsFieldStatus,
	formsFieldStatusForm,
	formsFieldStatusList,
	formsContent,
	formsContentList,
	formsContentForm,
	formsFieldContentRelationList,
	formsFieldContentRelationForm,
	formsContentStatus,
	formsContentStatusForm,
	formsContentStatusList,
};

export default config;
