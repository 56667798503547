
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Posts',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Posts',

	pageUrl: 'posts',
	pageTitle: 'Posts',
};

export default config;
