
const verify = {
	pageTitle: 'Activation',
	pageUrl: 'verify',

	id: 'sso-verify',
	storeName: 'sso-verify',
	apiUrl: 'user/verify',
	orderInHeaderTabMenu: 1,
};

export default verify;
