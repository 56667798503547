import { 
	actionDialogOpen,
	actionApiFormRestore, 
} from '@nest-datum-ui/Store';

const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Settings',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Settings',
	
	pageUrl: 'settings',
	pageTitle: 'Settings',

	list: {
		id: 'cv-settings-list',
		storeName: 'cv-settings-list',
		apiUrl: 'setting',
		initialPage: 1,
		initialLimit: 20,
		bulkDeletion: true,
		search: true,
		withContextMenu: true,
		withFilter: true,

		manage: {
			drop: {
				text: (index, selected, selectedForDrop, selectedForDropPermanently) => `Disable checked (${selectedForDrop.length})`,
				showStrategy: (selected, selectedForDrop, selectedForDropPermanently) => selectedForDrop.length > 0,
				onClick: (e, index, selected, selectedForDrop, selectedForDropPermanently) => actionDialogOpen('disable-many', { ids: selectedForDrop })(),
				order: 0,
				variant: 'contained',
				color: 'error',
			},
			dropPermanently: {
				text: (index, selected, selectedForDrop, selectedForDropPermanently) => `Delete checked (${selectedForDropPermanently.length})`,
				showStrategy: (selected, selectedForDrop, selectedForDropPermanently) => selectedForDropPermanently.length > 0,
				onClick: (e, index, selected, selectedForDrop, selectedForDropPermanently) => actionDialogOpen('drop-many', { ids: selectedForDropPermanently })(),
				order: 1,
				variant: 'contained',
				color: 'error',
			},
			restore: {
				text: (index, selected, selectedForDrop, selectedForDropPermanently) => `Restore (${selectedForDropPermanently.length})`,
				showStrategy: (selected, selectedForDrop, selectedForDropPermanently) => selectedForDropPermanently.length > 0,
				onClick: (e, index, selected, selectedForDrop, selectedForDropPermanently, context) => actionApiFormRestore(context.storeName, { apiUrl: context.apiFullUrl, ids: selectedForDropPermanently })(),
				order: 2,
				variant: 'contained',
				color: 'primary',
			},
			create: {
				text: 'Create',
				to: 'settings/0',
				order: 3,
				variant: 'contained',
				color: 'secondary',
			},
		},
		filters: {
			isDeleted: true,
			isNotDeleted: true,
		},
		rowColumns: [{
			name: 'ID',
			id: 'id',
			sortable: true,
			order: 0,
		}, {
			name: 'Main',
			id: 'main',
			order: 1,
		}, {
			name: 'Data type',
			id: 'dataType',
			order: 2,
		}, {
			name: 'Value',
			id: 'value',
			order: 3,
		}, {
			name: 'Story',
			id: 'createdAt',
			sortable: true,
			order: 4,
		}],
	},

	form: {
		displayInBreadcrumbsMenu: true,
		breadcrumbsMenuTitle: 'Item',

		pageUrl: 'settings/:id',
		pageTitle: 'Setting',

		id: 'cv-setting-form',
		storeName: 'cv-setting-form',
		apiUrl: 'setting',

		manage: {
			create: {
				text: 'Save',
				order: 0,
			},
			dropOnRemovable: {
				text: 'Disable',
				order: 1,
			},
		},
	},
};

export default config;
