import jobsSetting from './setting.js';
import jobsSettingForm from './settingForm.js';
import jobsSettingList from './settingList.js';
import jobsAccess from './access.js';
import jobsAccessForm from './accessForm.js';
import jobsAccessFormRelationList from './accessFormRelationList.js';
import jobsAccessFormRelationForm from './accessFormRelationForm.js';
import jobsAccessList from './accessList.js';
import jobsAccessOption from './accessOption.js';
import jobsAccessOptionRelationForm from './accessOptionRelationForm.js';
import jobsAccessOptionRelationList from './accessOptionRelationList.js';
import jobsAccessOptionForm from './accessOptionForm.js';
import jobsAccessOptionList from './accessOptionList.js';
import jobsAccessStatus from './accessStatus.js';
import jobsAccessStatusForm from './accessStatusForm.js';
import jobsAccessStatusList from './accessStatusList.js';
import jobsPost from './post.js';
import jobsPostForm from './postForm.js';
import jobsPostContentRelationForm from './postContentRelationForm.js';
import jobsPostContentRelationList from './postContentRelationList.js';
import jobsPostList from './postList.js';
import jobsPostOption from './postOption.js';
import jobsPostOptionRelationForm from './postOptionRelationForm.js';
import jobsPostOptionRelationList from './postOptionRelationList.js';
import jobsPostOptionForm from './postOptionForm.js';
import jobsPostOptionList from './postOptionList.js';
import jobsPostStatus from './postStatus.js';
import jobsPostStatusForm from './postStatusForm.js';
import jobsPostStatusList from './postStatusList.js';
import jobsCategory from './category.js';
import jobsCategoryForm from './categoryForm.js';
import jobsCategoryList from './categoryList.js';
import jobsCategoryOption from './categoryOption.js';
import jobsCategoryOptionRelationForm from './categoryOptionRelationForm.js';
import jobsCategoryOptionRelationList from './categoryOptionRelationList.js';
import jobsCategoryOptionForm from './categoryOptionForm.js';
import jobsCategoryOptionList from './categoryOptionList.js';
import jobsCategoryStatus from './categoryStatus.js';
import jobsCategoryStatusForm from './categoryStatusForm.js';
import jobsCategoryStatusList from './categoryStatusList.js';
import jobsCompany from './company.js';
import jobsCompanyForm from './companyForm.js';
import jobsCompanyList from './companyList.js';
import jobsCompanyOption from './companyOption.js';
import jobsCompanyOptionRelationForm from './companyOptionRelationForm.js';
import jobsCompanyOptionRelationList from './companyOptionRelationList.js';
import jobsCompanyOptionForm from './companyOptionForm.js';
import jobsCompanyOptionList from './companyOptionList.js';
import jobsCompanyStatus from './companyStatus.js';
import jobsCompanyStatusForm from './companyStatusForm.js';
import jobsCompanyStatusList from './companyStatusList.js';

const config = {
	name: 'jobs',
	pagePrefixUrl: 'app',
	pageSeparateBaseUrl: 'jobs',
	pageBaseUrl: 'jobs',
	pageInitialUrl: 'posts',
	apiBaseUrl: process.env.URL_API_JOBS,
	title: 'Jobs',
	
	jobsSetting,
	jobsSettingForm,
	jobsSettingList,
	jobsAccess,
	jobsAccessForm,
	jobsAccessFormRelationList,
	jobsAccessFormRelationForm,
	jobsAccessList,
	jobsAccessOption,
	jobsAccessOptionRelationForm,
	jobsAccessOptionRelationList,
	jobsAccessOptionForm,
	jobsAccessOptionList,
	jobsAccessStatus,
	jobsAccessStatusForm,
	jobsAccessStatusList,
	jobsPost,
	jobsPostForm,
	jobsPostContentRelationForm,
	jobsPostContentRelationList,
	jobsPostList,
	jobsPostOption,
	jobsPostOptionRelationForm,
	jobsPostOptionRelationList,
	jobsPostOptionForm,
	jobsPostOptionList,
	jobsPostStatus,
	jobsPostStatusForm,
	jobsPostStatusList,
	jobsCategory,
	jobsCategoryForm,
	jobsCategoryList,
	jobsCategoryOption,
	jobsCategoryOptionRelationForm,
	jobsCategoryOptionRelationList,
	jobsCategoryOptionForm,
	jobsCategoryOptionList,
	jobsCategoryStatus,
	jobsCategoryStatusForm,
	jobsCategoryStatusList,
	jobsCompany,
	jobsCompanyForm,
	jobsCompanyList,
	jobsCompanyOption,
	jobsCompanyOptionRelationForm,
	jobsCompanyOptionRelationList,
	jobsCompanyOptionForm,
	jobsCompanyOptionList,
	jobsCompanyStatus,
	jobsCompanyStatusForm,
	jobsCompanyStatusList,
};

export default config;
