
const userOption = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Options',

	pageUrl: 'users/options',
	pageTitle: 'Options',

	fieldsBlock: true,
	fieldsBlockTitle: 'Options:',
	orderInHeaderTabMenu: 2,
};

export default userOption;
