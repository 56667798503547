import countriesSetting from './setting.js';
import countriesSettingForm from './settingForm.js';
import countriesSettingList from './settingList.js';
import countriesAccess from './access.js';
import countriesAccessForm from './accessForm.js';
import countriesAccessFormRelationList from './accessFormRelationList.js';
import countriesAccessFormRelationForm from './accessFormRelationForm.js';
import countriesAccessList from './accessList.js';
import countriesAccessOption from './accessOption.js';
import countriesAccessOptionRelationForm from './accessOptionRelationForm.js';
import countriesAccessOptionRelationList from './accessOptionRelationList.js';
import countriesAccessOptionForm from './accessOptionForm.js';
import countriesAccessOptionList from './accessOptionList.js';
import countriesAccessStatus from './accessStatus.js';
import countriesAccessStatusForm from './accessStatusForm.js';
import countriesAccessStatusList from './accessStatusList.js';
import countriesRegion from './region.js';
import countriesRegionForm from './regionForm.js';
import countriesRegionContentRelationForm from './regionContentRelationForm.js';
import countriesRegionContentRelationList from './regionContentRelationList.js';
import countriesRegionList from './regionList.js';
import countriesRegionOption from './regionOption.js';
import countriesRegionOptionRelationForm from './regionOptionRelationForm.js';
import countriesRegionOptionRelationList from './regionOptionRelationList.js';
import countriesRegionOptionForm from './regionOptionForm.js';
import countriesRegionOptionList from './regionOptionList.js';
import countriesRegionStatus from './regionStatus.js';
import countriesRegionStatusForm from './regionStatusForm.js';
import countriesRegionStatusList from './regionStatusList.js';
import countriesType from './type.js';
import countriesTypeForm from './typeForm.js';
import countriesTypeList from './typeList.js';
import countriesTypeOption from './typeOption.js';
import countriesTypeOptionRelationForm from './typeOptionRelationForm.js';
import countriesTypeOptionRelationList from './typeOptionRelationList.js';
import countriesTypeOptionForm from './typeOptionForm.js';
import countriesTypeOptionList from './typeOptionList.js';
import countriesTypeStatus from './typeStatus.js';
import countriesTypeStatusForm from './typeStatusForm.js';
import countriesTypeStatusList from './typeStatusList.js';

const config = {
	name: 'countries',
	pagePrefixUrl: 'app',
	pageSeparateBaseUrl: 'countries',
	pageBaseUrl: 'countries',
	pageInitialUrl: 'regions',
	apiBaseUrl: process.env.URL_API_COUNTRIES,
	title: 'Countries',
	
	countriesSetting,
	countriesSettingForm,
	countriesSettingList,
	countriesAccess,
	countriesAccessForm,
	countriesAccessFormRelationList,
	countriesAccessFormRelationForm,
	countriesAccessList,
	countriesAccessOption,
	countriesAccessOptionRelationForm,
	countriesAccessOptionRelationList,
	countriesAccessOptionForm,
	countriesAccessOptionList,
	countriesAccessStatus,
	countriesAccessStatusForm,
	countriesAccessStatusList,
	countriesRegion,
	countriesRegionForm,
	countriesRegionContentRelationForm,
	countriesRegionContentRelationList,
	countriesRegionList,
	countriesRegionOption,
	countriesRegionOptionRelationForm,
	countriesRegionOptionRelationList,
	countriesRegionOptionForm,
	countriesRegionOptionList,
	countriesRegionStatus,
	countriesRegionStatusForm,
	countriesRegionStatusList,
	countriesType,
	countriesTypeForm,
	countriesTypeList,
	countriesTypeOption,
	countriesTypeOptionRelationForm,
	countriesTypeOptionRelationList,
	countriesTypeOptionForm,
	countriesTypeOptionList,
	countriesTypeStatus,
	countriesTypeStatusForm,
	countriesTypeStatusList,
};

export default config;
