
const accessOption = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Options',

	pageUrl: 'accesses/options',
	pageTitle: 'Options',

	fieldsBlock: true,
	fieldsBlockTitle: 'Options:',
	orderInHeaderTabMenu: 2,
};

export default accessOption;
