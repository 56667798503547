import React from 'react';

let Dashboard = () => {
	return <React.Fragment>
		Dashboard
	</React.Fragment>;
};

Dashboard = React.memo(Dashboard);
Dashboard.defaultProps = {
};
Dashboard.propTypes = {
};

export default Dashboard;
