
const categoryStatus = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Statuses',

	pageUrl: 'categories/statuses',
	pageTitle: 'Statuses',
	orderInHeaderTabMenu: 2,
};

export default categoryStatus;
