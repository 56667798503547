import styled from 'styled-components';
import Box from '@mui/material/Box';

const Manager = styled(Box)`
	position: relative;
	height: 137px;
	border-top: 1px solid #e0e0e0;
	display: flex;
	align-items: center;
	justify-content: space-around;
`;

export default Manager;