
const config = {
	name: 'john-connor',
	pageBaseUrl: 'john-connor',
	pageInitialUrl: '',
	apiBaseUrl: process.env.URL_API_JOHN_CONNOR,
	title: 'John Connor',
};

export default config;
