
const SignUp = {
	pageTitle: 'Sign up',
	pageUrl: 'sign-up',
			
	id: 'sso-sign-up',
	storeName: 'sso-sign-up',
	apiUrl: 'user/register',
	orderInHeaderTabMenu: 1,
};

export default SignUp;
