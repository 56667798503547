
const contentForm = {
	parentName: 'formsContent',
	statusName: 'formsContentStatus',
	statusListName: 'formsContentStatusList',
	relationListName: 'formsFieldContentRelationList',

	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'content',

	pageUrl: 'contents/:id',
	pageTitle: 'Content',
	orderInHeaderTabMenu: 2,
	id: 'forms-content-form',
	storeName: 'forms-content-form',
	apiUrl: 'content',

	manage: {
		create: {
			text: 'Save',
			order: 0,
		},
		dropOnRemovable: {
			text: 'Disable',
			order: 1,
		},
	},
};

export default contentForm;
