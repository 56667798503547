
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Contents',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Contents',

	pageUrl: 'contents',
	pageTitle: 'Contents',
};

export default config;
