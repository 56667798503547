
const fieldStatus = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Statuses',

	pageUrl: 'fields/statuses',
	pageTitle: 'Statuses',
	orderInHeaderTabMenu: 2,
};

export default fieldStatus;
