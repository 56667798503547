
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Fields',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Fields',

	pageUrl: 'fields',
	pageTitle: 'Fields',
};

export default config;
