
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Categories',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Categories',

	pageUrl: 'categories',
	pageTitle: 'Categories',
};

export default config;
