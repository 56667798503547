
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Forms',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Forms',

	pageUrl: 'forms',
	pageTitle: 'Forms',
};

export default config;
