
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Companies',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Companies',

	pageUrl: 'companies',
	pageTitle: 'Companies',
};

export default config;
