
const phoneList = {
	'country-ua': {
		id: 'country-ua',
		isoCode1: 'UA',
		isoCode2: 'UKR',
		phoneCode: '380',
		maxCount: 9,
	},
	'country-de': {
		id: 'country-de',
		isoCode1: 'DE',
		isoCode2: 'DEU',
		phoneCode: '49',
		maxCount: 11,
	},
	'country-usa': {
		id: 'country-usa',
		isoCode1: 'US',
		isoCode2: 'USA',
		phoneCode: '1',
		maxCount: 10,
	},
};

export default phoneList;
