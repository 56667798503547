
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Letters',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Letters',

	pageUrl: 'letters',
	pageTitle: 'Letters',
};

export default config;
