import Progress from './Progress.jsx';
import Select from './Select';
import Small from './Small';
import Table from './Table';

export default Progress;
export {
	Select,
	Small,
	Table,
};
