import Input from './Input.jsx';
import Bool from './Bool';
import Date from './Date';
import DateTime from './DateTime';
import Email from './Email';
import Float from './Float';
import Int from './Int';
import Mixed from './Mixed';
import Password from './Password';
import Phone from './Phone';
import Text from './Text';
import Time from './Time';

export default Input;
export {
	Bool,
	Date,
	DateTime,
	Email,
	Float,
	Int,
	Mixed,
	Password,
	Phone,
	Text,
	Time,
};
