
const SignIn = {
	pageTitle: 'Sign in',
	pageFullUrl: process.env.ROUTE_SiGN_IN,
			
	id: 'sso-sign-in',
	storeName: 'sso-sign-in',
	apiUrl: 'user/login',
	orderInHeaderTabMenu: 1,
};

export default SignIn;
