import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled(Box)`
	position: relative;
	width: 100%;

	& .MuiFormHelperText-root {
		margin-left: 0px;
	}
`;

export default Wrapper;