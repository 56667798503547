import dictionarySetting from './setting.js';
import dictionarySettingForm from './settingForm.js';
import dictionarySettingList from './settingList.js';
import dictionaryAccess from './access.js';
import dictionaryAccessForm from './accessForm.js';
import dictionaryAccessFormRelationList from './accessFormRelationList.js';
import dictionaryAccessFormRelationForm from './accessFormRelationForm.js';
import dictionaryAccessList from './accessList.js';
import dictionaryAccessOption from './accessOption.js';
import dictionaryAccessOptionRelationForm from './accessOptionRelationForm.js';
import dictionaryAccessOptionRelationList from './accessOptionRelationList.js';
import dictionaryAccessOptionForm from './accessOptionForm.js';
import dictionaryAccessOptionList from './accessOptionList.js';
import dictionaryAccessStatus from './accessStatus.js';
import dictionaryAccessStatusForm from './accessStatusForm.js';
import dictionaryAccessStatusList from './accessStatusList.js';
import dictionaryPost from './post.js';
import dictionaryPostForm from './postForm.js';
import dictionaryPostContentRelationForm from './postContentRelationForm.js';
import dictionaryPostContentRelationList from './postContentRelationList.js';
import dictionaryPostList from './postList.js';
import dictionaryPostOption from './postOption.js';
import dictionaryPostOptionRelationForm from './postOptionRelationForm.js';
import dictionaryPostOptionRelationList from './postOptionRelationList.js';
import dictionaryPostOptionForm from './postOptionForm.js';
import dictionaryPostOptionList from './postOptionList.js';
import dictionaryPostStatus from './postStatus.js';
import dictionaryPostStatusForm from './postStatusForm.js';
import dictionaryPostStatusList from './postStatusList.js';
import dictionaryCategory from './category.js';
import dictionaryCategoryForm from './categoryForm.js';
import dictionaryCategoryList from './categoryList.js';
import dictionaryCategoryOption from './categoryOption.js';
import dictionaryCategoryOptionRelationForm from './categoryOptionRelationForm.js';
import dictionaryCategoryOptionRelationList from './categoryOptionRelationList.js';
import dictionaryCategoryOptionForm from './categoryOptionForm.js';
import dictionaryCategoryOptionList from './categoryOptionList.js';
import dictionaryCategoryStatus from './categoryStatus.js';
import dictionaryCategoryStatusForm from './categoryStatusForm.js';
import dictionaryCategoryStatusList from './categoryStatusList.js';

const config = {
	name: 'dictionary',
	pagePrefixUrl: 'app',
	pageSeparateBaseUrl: 'dictionary',
	pageBaseUrl: 'dictionary',
	pageInitialUrl: 'posts',
	apiBaseUrl: process.env.URL_API_DICTIONARY,
	title: 'Dictionary',
	
	dictionarySetting,
	dictionarySettingForm,
	dictionarySettingList,
	dictionaryAccess,
	dictionaryAccessForm,
	dictionaryAccessFormRelationList,
	dictionaryAccessFormRelationForm,
	dictionaryAccessList,
	dictionaryAccessOption,
	dictionaryAccessOptionRelationForm,
	dictionaryAccessOptionRelationList,
	dictionaryAccessOptionForm,
	dictionaryAccessOptionList,
	dictionaryAccessStatus,
	dictionaryAccessStatusForm,
	dictionaryAccessStatusList,
	dictionaryPost,
	dictionaryPostForm,
	dictionaryPostContentRelationForm,
	dictionaryPostContentRelationList,
	dictionaryPostList,
	dictionaryPostOption,
	dictionaryPostOptionRelationForm,
	dictionaryPostOptionRelationList,
	dictionaryPostOptionForm,
	dictionaryPostOptionList,
	dictionaryPostStatus,
	dictionaryPostStatusForm,
	dictionaryPostStatusList,
	dictionaryCategory,
	dictionaryCategoryForm,
	dictionaryCategoryList,
	dictionaryCategoryOption,
	dictionaryCategoryOptionRelationForm,
	dictionaryCategoryOptionRelationList,
	dictionaryCategoryOptionForm,
	dictionaryCategoryOptionList,
	dictionaryCategoryStatus,
	dictionaryCategoryStatusForm,
	dictionaryCategoryStatusList,
};

export default config;
