import styled from 'styled-components';
import Box from '@mui/material/Box';

const List = styled(Box)`
	position: relative;
	height: calc(100% - 138px);
	overflow-y: auto:
	overflow-x: hidden;
`;

export default List;