import filesSetting from './setting.js';
import filesSettingForm from './settingForm.js';
import filesSettingList from './settingList.js';
import filesAccess from './access.js';
import filesAccessForm from './accessForm.js';
import filesAccessFormRelationList from './accessFormRelationList.js';
import filesAccessFormRelationForm from './accessFormRelationForm.js';
import filesAccessList from './accessList.js';
import filesAccessOption from './accessOption.js';
import filesAccessOptionRelationForm from './accessOptionRelationForm.js';
import filesAccessOptionRelationList from './accessOptionRelationList.js';
import filesAccessOptionForm from './accessOptionForm.js';
import filesAccessOptionList from './accessOptionList.js';
import filesAccessStatus from './accessStatus.js';
import filesAccessStatusForm from './accessStatusForm.js';
import filesAccessStatusList from './accessStatusList.js';
import filesManager from './manager.js';
import filesManagerList from './managerList.js';
import filesProvider from './provider.js';
import filesProviderForm from './providerForm.js';
import filesProviderFormRelationList from './providerFormRelationList.js';
import filesProviderFormRelationForm from './providerFormRelationForm.js';
import filesProviderList from './providerList.js';
import filesProviderOption from './providerOption.js';
import filesProviderOptionRelationForm from './providerOptionRelationForm.js';
import filesProviderOptionRelationList from './providerOptionRelationList.js';
import filesProviderOptionForm from './providerOptionForm.js';
import filesProviderOptionList from './providerOptionList.js';
import filesProviderStatus from './providerStatus.js';
import filesProviderStatusForm from './providerStatusForm.js';
import filesProviderStatusList from './providerStatusList.js';
import filesSystem from './system.js';
import filesSystemForm from './systemForm.js';
import filesSystemFormRelationList from './systemFormRelationList.js';
import filesSystemFormRelationForm from './systemFormRelationForm.js';
import filesSystemList from './systemList.js';
import filesSystemOption from './systemOption.js';
import filesSystemOptionRelationForm from './systemOptionRelationForm.js';
import filesSystemOptionRelationList from './systemOptionRelationList.js';
import filesSystemOptionForm from './systemOptionForm.js';
import filesSystemOptionList from './systemOptionList.js';
import filesSystemStatus from './systemStatus.js';
import filesSystemStatusForm from './systemStatusForm.js';
import filesSystemStatusList from './systemStatusList.js';

const config = {
	name: 'files',
	pagePrefixUrl: 'app',
	pageSeparateBaseUrl: 'files',
	pageBaseUrl: 'files',
	pageInitialUrl: 'manager',
	apiBaseUrl: process.env.URL_API_FILES,
	title: 'Files',
	
	filesSetting,
	filesSettingForm,
	filesSettingList,
	filesAccess,
	filesAccessForm,
	filesAccessFormRelationList,
	filesAccessFormRelationForm,
	filesAccessList,
	filesAccessOption,
	filesAccessOptionRelationForm,
	filesAccessOptionRelationList,
	filesAccessOptionForm,
	filesAccessOptionList,
	filesAccessStatus,
	filesAccessStatusForm,
	filesAccessStatusList,
	filesManager,
	filesManagerList,
	filesProvider,
	filesProviderForm,
	filesProviderFormRelationList,
	filesProviderFormRelationForm,
	filesProviderList,
	filesProviderOption,
	filesProviderOptionRelationForm,
	filesProviderOptionRelationList,
	filesProviderOptionForm,
	filesProviderOptionList,
	filesProviderStatus,
	filesProviderStatusForm,
	filesProviderStatusList,
	filesSystem,
	filesSystemForm,
	filesSystemFormRelationList,
	filesSystemFormRelationForm,
	filesSystemList,
	filesSystemOption,
	filesSystemOptionRelationForm,
	filesSystemOptionRelationList,
	filesSystemOptionForm,
	filesSystemOptionList,
	filesSystemStatus,
	filesSystemStatusForm,
	filesSystemStatusList,
};

export default config;
