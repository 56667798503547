
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Reports',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Reports',

	pageUrl: 'reports',
	pageTitle: 'Reports',
};

export default config;
