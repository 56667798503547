
const postStatus = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Statuses',

	pageUrl: 'posts/statuses',
	pageTitle: 'Statuses',
	orderInHeaderTabMenu: 2,
};

export default postStatus;
