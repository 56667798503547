
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Providers',

	displayInHeaderTabMenu: true,
	headerTabMenuTitle: 'Providers',

	pageUrl: 'providers',
	pageTitle: 'Providers',
	orderInHeaderTabMenu: 1,
};

export default config;
