
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Templates',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Templates',

	pageUrl: 'templates',
	pageTitle: 'Templates',
};

export default config;
