
const accessOptionForm = {
	parentName: 'mailAccessOption',
	relationListName: 'mailAccessOptionRelationList',

	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Option',

	pageUrl: 'accesses/options/:id',
	pageTitle: 'Option',

	id: 'mail-accesses-options-form',
	storeName: 'mail-accesses-options-form',
	apiUrl: 'access-option',
	apiRelationUrl: 'access/option/:id',
	orderInHeaderTabMenu: 2,
	entity: 'accessId', 
	entityRelation: 'accessOptionId',
	entityOptionRelation: 'accessAccessOptionId',
	relation: 'accessAccessOptions', 
	relationContent: 'accessAccessAccessOptions',

	manage: {
		create: {
			text: 'Save',
			order: 0,
		},
		dropOnRemovable: {
			text: 'Disable',
			order: 1,
		},
	},
};

export default accessOptionForm;
