
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Systems',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 1,
	headerTabMenuTitle: 'Systems',

	pageUrl: 'systems',
	pageTitle: 'Systems',
};

export default config;
